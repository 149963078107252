import { Module } from 'vuex'
import { RootState } from '@/store'
import mutations from '@/store/modules/payments/mutations'
import actions from '@/store/modules/payments/actions'
import getters from '@/store/modules/payments/getters'

export class PaymentsState {
  unpaidOverdueCounters = new UnpaidOverdueCounters()
}

class UnpaidOverdueCounters {
  payments = 0
  invoices = 0

  get all(): number {
    return this.payments + this.invoices
  }
}

export const modulePayments: Module<PaymentsState, RootState> = {
  state: () => new PaymentsState(),
  getters,
  actions,
  mutations,
}
