import { MutationTree } from 'vuex'
import { PaymentsState } from '@/store/modules/payments/state'

export const M_PAYMENTS_OVERDUE_COUNT = 'M_PAYMENTS_OVERDUE_COUNT'
export const M_INVOICES_OVERDUE_COUNT = 'M_INVOICES_OVERDUE_COUNT'

const mutations: MutationTree<PaymentsState> = {
  [M_PAYMENTS_OVERDUE_COUNT]: (state, count: number) => {
    state.unpaidOverdueCounters.payments = count
  },
  [M_INVOICES_OVERDUE_COUNT]: (state, count: number) => {
    state.unpaidOverdueCounters.invoices = count
  },
}

export default mutations
