import { CommonModel, IModel } from '@/models/Model'
import { MainStore } from '@/store/MainStore'
import { paymentsApi } from '@/api/payments-api'

export interface ICompanyBankAccount extends IModel {
  id: number
  companyID: number
  name: string
  commercialCode: string
  sepaIdentification: string
  iban: string
}

export class CompanyBankAccount extends CommonModel implements ICompanyBankAccount {
  id = 0
  companyID = 0
  name = ''
  commercialCode = ''
  sepaIdentification = '' //generated automatically on the server
  iban = ''

  get titleHtml(): string {
    const res: string[] = []
    this.name && res.push(`<i>Name:</i> <b>${this.name}</b>`)
    this.commercialCode && res.push(`<i>code:</i> <b>${this.commercialCode}</b>`)
    this.sepaIdentification && res.push(`<i>SEPA ident.:</i> <b>${this.sepaIdentification}</b>`)
    this.iban && res.push(`<i>IBAN:</i> <b>${this.iban}</b>`)

    return res.join(', ')
  }

  constructor(src: ICompanyBankAccount | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: ICompanyBankAccount) {
    Object.assign(this, src)

    this.initModel(src)
  }

  async update(): Promise<boolean> {
    try {
      this.init(await paymentsApi.companyBankAccount.update(this.toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  toInterface(): ICompanyBankAccount {
    return {
      id: this.id,
      companyID: this.companyID,
      name: this.name,
      commercialCode: this.commercialCode,
      sepaIdentification: this.sepaIdentification,
      iban: this.iban,
    }
  }
}
