import { CommonModel, IModel } from '@/models/Model'
import { IPayment, Payment } from '@/models/payments/Payment'
import { UnitType, unitTypes } from '@/models/tariffs/constants'
import { Discount } from '@/models/discounts/Discount'
import { Money } from '@/models/Money'
import { Price } from '@/models/tariffs/Price'
import { Product } from '@/models/products/Product'
import { AdditionalCharge } from '@/models/subscription/AdditionalCharge'

export interface IPaymentLine extends IModel {
  id: number
  paymentID: number
  priceID: number | null
  productID: number | null
  additionalChargeID: number | null
  discountID: number | null
  subscriptionID: number | null
  description: string
  total: number
  discountValue: number
  vat: number
  standbyCost: number
  unitPrice: number
  unitQuantity: number
  unitType: UnitType
  numberCombined: number
  amountPeriods: number
  serviceStartDate: Date | null
  payment: IPayment | null
}

export class PaymentLine extends CommonModel implements IPaymentLine {
  id = 0
  paymentID = 0
  priceID: number | null = null
  productID: number | null = null
  additionalChargeID: number | null = null
  discountID: number | null = null
  subscriptionID = null
  description = ''
  total = 0
  discountValue = 0
  vat = 0
  standbyCost = 0
  unitPrice = 0
  unitQuantity = 0
  unitType: UnitType = 0
  numberCombined = 1
  amountPeriods = 0
  serviceStartDate: Date | null = null
  payment: Payment | null = null

  // for view
  moneyUnitPrice: Money = new Money()
  moneyTotal: Money = new Money()
  moneyDiscountValue: Money = new Money()
  moneyVAT: Money = new Money()
  moneyStandbyCost: Money = new Money()

  // after load
  price: Price | null = null
  product: Product | null = null
  additionalCharge: AdditionalCharge | null = null
  discount: Discount | null = null

  get quantity(): string {
    return this.amountPeriods > 1
      ? this.numberCombined + ' x ' + this.amountPeriods
      : this.numberCombined.toString()
  }

  get unitTypeHtml(): string {
    return unitTypes[this.unitType]
  }

  get totalUnitPrice(): Money {
    const res = new Money(this.moneyUnitPrice)
    res.value *= this.numberCombined > 0 ? this.numberCombined : 1
    return res
  }

  get priceName(): string {
    const res = this.priceID ? this.priceID.toString() : ''
    return this.price ? this.price.name : res
  }

  get priceTagHtml(): string {
    return this.price ? this.price.tagHtml : ''
  }

  get productName(): string {
    const res = this.productID ? this.productID.toString() : ''
    return this.product ? `(${res}) ${this.product.name}` : res
  }

  get additionalChargeName(): string {
    const res = this.additionalChargeID ? this.additionalChargeID.toString() : ''
    return this.additionalCharge ? `(${res}) ${this.additionalCharge.title}` : res
  }

  get discountHtml(): string {
    return this.discount ? this.discount.titleHtml : ''
  }

  get priceMinContractTerm(): number {
    return this.price ? this.price.minContractTerm : 0
  }

  constructor(src: IPaymentLine | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: IPaymentLine) {
    Object.assign(this, src)
    this.payment = src.payment ? new Payment(src.payment) : null

    this.moneyUnitPrice = new Money(src.unitPrice)
    this.moneyTotal = new Money(src.total)
    this.moneyDiscountValue = new Money(src.discountValue)
    this.moneyVAT = new Money(src.vat)
    this.moneyStandbyCost = new Money(src.standbyCost)

    this.initModel(src)
  }

  setVatPercentage(vat: number): void {
    this.moneyUnitPrice.withVatPercentage(vat)
    this.moneyTotal.withVatPercentage(vat)
    this.moneyDiscountValue.withVatPercentage(vat)
    this.moneyVAT.withVatPercentage(vat)
    this.moneyStandbyCost.withVatPercentage(vat)
  }
}
