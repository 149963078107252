import { CommonModel, IModel } from '@/models/Model'
import { MainStore } from '@/store/MainStore'
import { personsApi } from '@/api/persons-api'
import { Discount } from '@/models/discounts/Discount'
import { Address } from '@/models/persons/Address'
import { PersonSimple } from '@/models/persons/PersonSimple'
import { Subscription } from '@/models/subscription/Subscription'
import { settings } from '@/models/Settings'
import { downloadBlob, formatDate, openNewTabBlob } from '@/utils'
import { Company } from '@/models/payments/Company'
import { AdditionalCharge } from '@/models/subscription/AdditionalCharge'
import { Payment } from '@/models/payments/Payment'
import { PaymentInvoice } from '@/models/payments/PaymentInvoice'
import { Receiver } from '@/models/payments/Receiver'
import { PersonPaymentMethod } from '@/models/payments/PersonPaymentMethod'
import { NetworkType, networkTypes, SubscriptionStatus } from '@/models/subscription/constants'
import { checkHtml } from '@/utils/html'
import { downloadApi } from '@/api/downloads-api'
import { contractToPersonRedirectHtml } from '@/models/persons/views'
import { FiberStatus } from '@/models/persons/constants'
import { Equipment } from '@/models/stock/Equipment'
import { loaderWrapper } from '@/utils/loaderWrapper'
import { SettingType } from '@/models/service/constants'
import { Service } from '@/models/service/Service'
import { PersonSearch } from '@/models/search/PersonSearch'

export function formatContractID(id: number, showEmpty = false): string {
  return id > 0
    ? settings().contractPrefix + id.toString().padStart(settings().sizeContractID, '0')
    : showEmpty
    ? settings().contractPrefix + id.toString().padStart(settings().sizeContractID, '0')
    : ''
}

export interface IContract extends IModel {
  id: number
  personID: number
  addressID: number
  invoiceAddressID: number
  installerID: number
  originalInstallerID: number
  receiverID: number
  companyID: number
  personPaymentMethodID: number
  lastRemesaPaymentID: number
  networkType: NetworkType
  sendInvoice: boolean
  excludeFromSepa: boolean
  createdBy: number
  createdAt: Date | null
  canceledAt: Date | null
  updatedAt: Date | null
  address: Address | null
  invoiceAddress: Address | null
  person: PersonSimple | null
  installer: PersonSimple | null
  originalInstaller: PersonSimple | null
  whoChanged: PersonSimple | null
}

export class Contract extends CommonModel implements IContract {
  id = 0
  personID = 0
  addressID = 0
  invoiceAddressID = 0
  installerID = 0
  originalInstallerID = 0
  receiverID = 0
  companyID = 0
  personPaymentMethodID = 0
  lastRemesaPaymentID = 0
  networkType: NetworkType = 0
  sendInvoice = false
  excludeFromSepa = false
  createdBy = 0
  createdAt: Date | null = null
  canceledAt: Date | null = null
  updatedAt: Date | null = null
  address: Address | null = null
  invoiceAddress: Address | null = null
  person: PersonSimple | null = null
  installer: PersonSimple | null = null
  originalInstaller: PersonSimple | null = null

  // after load
  company: Company | null = null
  receiver: Receiver | null = null
  personPaymentMethod: PersonPaymentMethod | null = null
  discounts: Discount[] = []
  subscriptions: Subscription[] = []
  unpaidCharges: AdditionalCharge[] = []
  lastPayment: Payment | null = null
  remesaPayment: Payment | null = null
  lastPaymentInvoices: PaymentInvoice[] = []
  fibreRouters: Equipment[] = []
  equipments: Equipment[] = []
  whoCreated: PersonSimple | null = null
  services: Service[] = []

  // only for searcher
  searcher = new PersonSearch()

  constructor(src: IContract | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: IContract) {
    Object.assign(this, src)

    this.address = src.address ? new Address(src.address) : null
    this.invoiceAddress = src.invoiceAddress ? new Address(src.invoiceAddress) : null
    this.person = src.person ? new PersonSimple(src.person) : null
    this.installer = src.installer ? new PersonSimple(src.installer) : null
    this.originalInstaller = src.originalInstaller ? new PersonSimple(src.originalInstaller) : null
    this.whoChanged = src.whoChanged ? new PersonSimple(src.whoChanged) : null
    this.createdAt = this.dateOrNull(src.createdAt)
    this.canceledAt = this.dateOrNull(src.canceledAt)

    this.initModel(src)
  }

  get servicesMap(): Map<number, Service> {
    const res = new Map<number, Service>()
    this.services.map((v) => res.set(v.id, v))

    return res
  }

  get isOriginInstallerDifferent(): boolean {
    if (!this.installer && this.originalInstaller) {
      return true
    }

    return Boolean(
      this.installer && this.originalInstaller && this.installer.id != this.originalInstaller.id
    )
  }

  get isCanceled(): boolean {
    return !!this.canceledAt
  }

  get companyHtml(): string {
    return this.company ? this.company.name : ''
  }

  get addressHtml(): string {
    const a = this.address ? this.address.fullName : ''

    if (this.invoiceAddress) {
      return this.address && this.invoiceAddress.id == this.address.id
        ? a
        : a + `<br><b>Invoice Address: </b>${this.invoiceAddress.fullName}`
    }

    return a
  }

  get formattedIdWithAddress(): string {
    const a = this.address ? this.address.fullName : ''
    return this.formattedID + (a ? ': ' + a : '')
  }

  get formattedIdWithPerson(): string {
    const a = this.person ? this.person.fullName : ''
    return this.formattedID + (a ? ': ' + a : '')
  }

  get receiverHtml(): string {
    return this.receiver ? this.receiver.shortName : ''
  }

  get receiverFullHtml(): string {
    return this.receiver ? this.receiver.titleWithCompanyHtml : ''
  }

  get payMethodHtml(): string {
    return this.personPaymentMethod ? this.personPaymentMethod.shortName : ''
  }

  get settingTypes(): SettingType[] {
    const types: SettingType[] = []
    this.subscriptions.forEach((subs) => {
      if (subs.allowShow) {
        subs.subscriptionServices.forEach((ss) => {
          ss.service && types.push(ss.service.settingType)
        })
      }
    })

    return [...new Set(types)]
  }

  get formattedID(): string {
    return formatContractID(this.id)
  }

  get createdAtHtml(): string {
    return formatDate(this.createdAt)
  }

  get canceledAtHtml(): string {
    return formatDate(this.canceledAt)
  }

  get personFullName(): string {
    return this.person ? this.person.fullName : ''
  }

  get toPersonRedirectHtml(): string {
    return contractToPersonRedirectHtml(this.id)
  }

  get whoCreatedName(): string {
    return this.whoCreated ? this.whoCreated.fullName : ''
  }

  get personLinkHtml(): string {
    return this.person ? this.person.viewLinkHtml() : ''
  }

  get installerLinkHtml(): string {
    return this.installer ? this.installer.viewLinkHtml('_self') : ''
  }

  get originalInstallerLinkHtml(): string {
    return this.originalInstaller ? this.originalInstaller.viewLinkHtml('_self') : ''
  }

  get allUnpaidPrepaidAndFiledRemesaCharges(): AdditionalCharge[] {
    return [
      ...this.unpaidCharges,
      ...this.subscriptions
        .filter((v) => v.isPrepaid || v.status == SubscriptionStatus.PaymentFailed)
        .map((v) => v.unpaidCharges)
        .flat(),
    ]
  }

  get allUnpaidRemesaCharges(): AdditionalCharge[] {
    return [
      ...this.unpaidCharges,
      ...this.subscriptions
        .filter((v) => !v.isPrepaid)
        .map((v) => v.unpaidCharges)
        .flat(),
    ]
  }

  get networkName(): string {
    return this.networkType ? networkTypes[this.networkType] : ''
  }

  get sendInvoiceHtml(): string {
    return checkHtml(this.sendInvoice)
  }

  get hasActiveRemesaSubscriptions(): boolean {
    return this.subscriptions.filter((v) => !v.isDeleted).length > 0
  }

  get hasNotRemovedRemesaSubscriptions(): boolean {
    return this.subscriptions.filter((v) => v.isRemesa && !v.isRemoved).length > 0
  }

  get fiberStatus(): FiberStatus {
    for (const v of this.subscriptions) {
      if (v.status == SubscriptionStatus.Active) {
        return FiberStatus.Active
      }
    }

    return FiberStatus.Stopped
  }

  get fiberStatusHtml(): string {
    return this.fiberStatus == FiberStatus.Active
      ? '<i class="fas fa-check text-success fs-1-2rem"></i>'
      : '<i class="fas fa-times text-danger fs-1-2rem"></i>'
  }

  get fibreRoutersSerials(): string[] {
    const res: string[] = []
    this.fibreRouters.forEach((v) => {
      if (v.serial) {
        res.push(v.serial)
      }
    })

    return res
  }

  async save(): Promise<boolean> {
    try {
      return this.id ? await this.update() : await this.add()
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async add(): Promise<boolean> {
    try {
      this.init(await personsApi.contract.add(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async update(): Promise<boolean> {
    try {
      this.init(await personsApi.contract.update(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async cancel(): Promise<boolean> {
    try {
      this.init(await personsApi.contract.cancel(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async toggleExcludeFromSepa(): Promise<boolean> {
    try {
      this.init(await personsApi.contract.updateExcludeFromSepa(this.id, !this.excludeFromSepa))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async printContract(inNewTab = true): Promise<void> {
    await loaderWrapper(async () => {
      const res = await downloadApi.agreement.contract({ id: this.id })
      inNewTab ? openNewTabBlob(res) : downloadBlob(res, 'contract_' + this.id)
    })
  }

  prepare(): Contract {
    this.companyID = this.company ? this.company.id : 0
    this.receiverID = this.receiver ? this.receiver.id : 0
    this.personPaymentMethodID = this.personPaymentMethod ? this.personPaymentMethod.id : 0
    this.installerID = this.installer ? this.installer.id : 0
    return this
  }

  toInterface(): IContract {
    return {
      id: this.id,
      personID: this.personID,
      addressID: this.addressID,
      invoiceAddressID: this.invoiceAddressID,
      installerID: this.installerID,
      originalInstallerID: this.originalInstallerID,
      receiverID: this.receiverID,
      companyID: this.companyID,
      personPaymentMethodID: this.personPaymentMethodID,
      lastRemesaPaymentID: this.lastRemesaPaymentID,
      networkType: this.networkType,
      sendInvoice: this.sendInvoice,
      excludeFromSepa: this.excludeFromSepa,
      createdBy: this.createdBy,
      canceledAt: this.canceledAt,
      createdAt: null,
      updatedAt: null,
      address: null,
      invoiceAddress: null,
      person: null,
      installer: null,
      originalInstaller: null,
      whoChanged: null,
    }
  }
}
