import { CommonModel, IModel } from '@/models/Model'
import { MainStore } from '@/store/MainStore'
import { paymentsApi } from '@/api/payments-api'

export interface ICompanyPaymentService extends IModel {
  id: number
  companyID: number
  name: string
}

export class CompanyPaymentService extends CommonModel implements ICompanyPaymentService {
  id = 0
  companyID = 0
  name = ''

  get titleHtml(): string {
    return `<i>Name:</i> <b>${this.name}</b>`
  }

  constructor(src: ICompanyPaymentService | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: ICompanyPaymentService) {
    Object.assign(this, src)

    this.initModel(src)
  }

  async update(): Promise<boolean> {
    try {
      this.init(await paymentsApi.companyPaymentService.update(this.toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  toInterface(): ICompanyPaymentService {
    return {
      id: this.id,
      companyID: this.companyID,
      name: this.name,
    }
  }
}
