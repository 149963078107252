import { ChartConfiguration } from 'chart.js'
import { CHART_COLORS, MONTHS } from '@/utils/chart'
import { PaymentMethodType } from '@/models/payments/constants'
import { paymentReport } from '@/components/dashboards/models/PaymentReport'
import { tooltipWithTotal } from '@/components/dashboards/models/common'

export function chartPaymentsCounts(data: paymentReport): ChartConfiguration {
  const countDataSEPA: number[] = []
  const countDataCard: number[] = []
  const countDataCash: number[] = []
  const countDataBankTransfer: number[] = []
  const countDataPartner: number[] = []
  const countDataOnline: number[] = []

  Object.entries(data).forEach((mr) => {
    mr[1].paymentMethod[PaymentMethodType.SEPA]
      ? countDataSEPA.push(mr[1].paymentMethod[PaymentMethodType.SEPA].count)
      : countDataSEPA.push(0)

    mr[1].paymentMethod[PaymentMethodType.Card]
      ? countDataCard.push(mr[1].paymentMethod[PaymentMethodType.Card].count)
      : countDataCard.push(0)

    mr[1].paymentMethod[PaymentMethodType.Cash]
      ? countDataCash.push(mr[1].paymentMethod[PaymentMethodType.Cash].count)
      : countDataCash.push(0)

    mr[1].paymentMethod[PaymentMethodType.BankTransfer]
      ? countDataBankTransfer.push(mr[1].paymentMethod[PaymentMethodType.BankTransfer].count)
      : countDataBankTransfer.push(0)

    mr[1].paymentMethod[PaymentMethodType.Partner]
      ? countDataPartner.push(mr[1].paymentMethod[PaymentMethodType.Partner].count)
      : countDataPartner.push(0)

    mr[1].paymentMethod[PaymentMethodType.Online]
      ? countDataOnline.push(mr[1].paymentMethod[PaymentMethodType.Online].count)
      : countDataOnline.push(0)
  })

  return {
    type: 'bar',
    data: {
      labels: [...MONTHS],
      datasets: [
        {
          label: 'SEPA',
          data: [...countDataSEPA],
          borderColor: CHART_COLORS.Red.main,
          backgroundColor: CHART_COLORS.Red.gradient,
          borderWidth: 1,
        },
        {
          label: 'Card',
          data: [...countDataCard],
          borderColor: CHART_COLORS.Blue.main,
          backgroundColor: CHART_COLORS.Blue.gradient,
          borderWidth: 1,
        },
        {
          label: 'Online',
          data: [...countDataOnline],
          borderColor: CHART_COLORS.Orange.main,
          backgroundColor: CHART_COLORS.Orange.gradient,
          borderWidth: 1,
        },
        {
          label: 'Cash',
          data: [...countDataCash],
          borderColor: CHART_COLORS.Yellow.main,
          backgroundColor: CHART_COLORS.Yellow.gradient,
          borderWidth: 1,
        },
        {
          label: 'BankTransfer',
          data: [...countDataBankTransfer],
          borderColor: CHART_COLORS.Green.main,
          backgroundColor: CHART_COLORS.Green.gradient,
          borderWidth: 1,
        },
        {
          label: 'Partner',
          data: [...countDataPartner],
          borderColor: CHART_COLORS.Purple.main,
          backgroundColor: CHART_COLORS.Purple.gradient,
          borderWidth: 1,
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          align: 'start',
          text: 'Number of payments',
          font: {
            size: 14,
            family: 'tahoma',
            weight: 'bold',
            style: 'italic',
          },
        },
        tooltip: tooltipWithTotal(),
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          beginAtZero: true,
          stacked: true,
        },
      },
    },
  }
}
