import { CommonModel, IModel } from '@/models/Model'
import { UnitType, unitTypes } from '@/models/tariffs/constants'
import { Discount } from '@/models/discounts/Discount'
import { Money } from '@/models/Money'
import { IPaymentInvoice, PaymentInvoice } from '@/models/payments/PaymentInvoice'
import { Price } from '@/models/tariffs/Price'
import { Product } from '@/models/products/Product'
import { AdditionalCharge } from '@/models/subscription/AdditionalCharge'

export interface IPaymentInvoiceLine extends IModel {
  id: number
  invoiceID: number
  priceID: number | null
  productID: number | null
  additionalChargeID: number | null
  discountID: number | null
  subscriptionID: number | null
  description: string
  total: number
  discountValue: number
  vat: number
  standbyCost: number
  unitPrice: number
  unitQuantity: number
  unitType: UnitType
  numberCombined: number
  amountPeriods: number
  invoice: IPaymentInvoice | null
}

export class PaymentInvoiceLine extends CommonModel implements IPaymentInvoiceLine {
  id = 0
  invoiceID = 0
  priceID: number | null = null
  productID: number | null = null
  additionalChargeID: number | null = null
  discountID: number | null = null
  subscriptionID: number | null = null
  description = ''
  total = 0
  discountValue = 0
  vat = 0
  standbyCost = 0
  unitPrice = 0
  unitQuantity = 1
  unitType = 0
  numberCombined = 0
  amountPeriods = 0
  invoice: PaymentInvoice | null = null

  // for view
  moneyUnitPrice: Money = new Money()
  moneyTotal: Money = new Money()
  moneyDiscountValue: Money = new Money()
  moneyVAT: Money = new Money()
  moneyStandbyCost: Money = new Money()

  // after load
  price: Price | null = null
  product: Product | null = null
  additionalCharge: AdditionalCharge | null = null
  discount: Discount | null = null

  get unitTypeHtml(): string {
    return unitTypes[this.unitType]
  }

  get priceName(): string {
    const res = this.priceID ? this.priceID.toString() : ''
    return this.price ? `(${res}) ${this.price.name}` : res
  }

  get productName(): string {
    const res = this.productID ? this.productID.toString() : ''
    return this.product ? `(${res}) ${this.product.name}` : res
  }

  get additionalChargeName(): string {
    const res = this.additionalChargeID ? this.additionalChargeID.toString() : ''
    return this.additionalCharge ? `(${res}) ${this.additionalCharge.title}` : res
  }

  get discountHtml(): string {
    return this.discount ? this.discount.titleHtml : ''
  }

  constructor(src: IPaymentInvoiceLine | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: IPaymentInvoiceLine) {
    Object.assign(this, src)
    this.invoice = src.invoice ? new PaymentInvoice(src.invoice) : null

    this.moneyUnitPrice = new Money(src.unitPrice)
    this.moneyTotal = new Money(src.total)
    this.moneyDiscountValue = new Money(src.discountValue)
    this.moneyVAT = new Money(src.vat)
    this.moneyStandbyCost = new Money(src.standbyCost)

    this.initModel(src)
  }

  calculate(vat = 21): PaymentInvoiceLine {
    this.prepare()
    this.total = this.unitPrice * this.unitQuantity - this.discountValue
    this.moneyTotal = new Money(this.total).withVatPercentage(vat)
    this.moneyVAT = Money.withValue(this.moneyTotal.vat).withVatPercentage(vat)
    this.vat = this.moneyVAT.forStorage

    return this
  }

  setVatPercentage(vat: number): void {
    this.moneyUnitPrice.withVatPercentage(vat)
    this.moneyTotal.withVatPercentage(vat)
    this.moneyDiscountValue.withVatPercentage(vat)
    this.moneyVAT.withVatPercentage(vat)
    this.moneyStandbyCost.withVatPercentage(vat)
  }

  prepare(): PaymentInvoiceLine {
    this.unitPrice = this.moneyUnitPrice.forStorage
    this.discountValue = this.moneyDiscountValue.forStorage

    return this
  }

  toInterface(): IPaymentInvoiceLine {
    return {
      id: this.id,
      invoiceID: this.invoiceID,
      priceID: this.priceID,
      productID: this.productID,
      additionalChargeID: this.additionalChargeID,
      discountID: this.discountID,
      subscriptionID: this.subscriptionID,
      description: this.description,
      total: this.total,
      discountValue: this.discountValue,
      vat: this.vat,
      standbyCost: this.standbyCost,
      unitPrice: this.unitPrice,
      unitQuantity: this.unitQuantity,
      unitType: this.unitType,
      numberCombined: this.numberCombined,
      amountPeriods: this.amountPeriods,
      invoice: null,
    }
  }
}
