import API, { paramsByIds } from '@/api/api'
import { DatesRequest, YearWithProducts } from '@/components/dashboards/models/requests'
import { Boxes } from '@/components/dashboards/models/Boxes'
import { AnnualReports } from '@/components/dashboards/models/AnnualReport'
import { PaymentBoxes } from '@/components/dashboards/models/PaymentBoxes'
import { monthlyReports, PaymentReports } from '@/components/dashboards/models/PaymentReport'

const MODULE = '/dashboards'

export const dashboardsApi = {
  boxes: async function (req: DatesRequest): Promise<Boxes> {
    const res = await API.get(API.buildURL(MODULE + '/boxes', { ...req }))
    return new Boxes((await res.json()) as Boxes)
  },

  annualReports: async function (year: number): Promise<AnnualReports> {
    const res = await API.get(API.buildURL(MODULE + '/annual-reports', { year }))
    return new AnnualReports(await res.json())
  },

  paymentsBoxes: async function (req: DatesRequest): Promise<PaymentBoxes> {
    const res = await API.get(API.buildURL(MODULE + '/payment/boxes', { ...req }))
    return new PaymentBoxes((await res.json()) as PaymentBoxes)
  },

  paymentsReports: async function (year: number): Promise<PaymentReports> {
    const res = await API.get(API.buildURL(MODULE + '/payment/reports', { year }))
    return new PaymentReports(await res.json())
  },

  paymentLinesWithProductReport: async function (req: YearWithProducts): Promise<monthlyReports> {
    const res = await API.get(
      API.buildURL(MODULE + '/payment/with-product', {
        year: req.year,
        ...paramsByIds(req.productIDs, 'productIDs'),
        ...paramsByIds(req.productGroupIDs, 'productGroupIDs'),
      })
    )
    return await res.json()
  },
}
