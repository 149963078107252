import store from '@/store'
import {
  A_PAYMENTS_INIT,
  A_PAYMENTS_INIT_ALL_UNPAID_OVERDUE,
} from '@/store/modules/payments/actions'

export class PaymentsStore {
  static init(): Promise<void> {
    return store.dispatch(A_PAYMENTS_INIT)
  }

  static async loadAllUnpaidOverdue(): Promise<void> {
    await store.dispatch(A_PAYMENTS_INIT_ALL_UNPAID_OVERDUE)
  }
}
