import { ChartConfiguration } from 'chart.js'
import { CHART_COLORS, MONTHS } from '@/utils/chart'
import { monthlyReports } from '@/components/dashboards/models/PaymentReport'
import { Money } from '@/models/Money'
import { tooltipCurrency } from '@/components/dashboards/models/common'

export function chartAverageBill(data: monthlyReports): ChartConfiguration {
  const res: number[] = []

  Object.entries(data).forEach((v) => {
    res.push(new Money(v[1].average).value)
  })

  return {
    type: 'bar',
    data: {
      labels: [...MONTHS],
      datasets: [
        {
          label: 'Average',
          data: [...res],
          borderColor: CHART_COLORS.Green.main,
          backgroundColor: CHART_COLORS.Green.gradient,
          borderWidth: 1,
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          align: 'start',
          text: 'Average Bills',
          font: {
            size: 14,
            family: 'tahoma',
            weight: 'bold',
            style: 'italic',
          },
        },
        tooltip: tooltipCurrency(),
      },
      scales: {
        y: {
          beginAtZero: true,
          stacked: true,
          ticks: {
            callback: function (value) {
              return value.toLocaleString('es-ES', { style: 'currency', currency: 'EUR' })
            },
          },
        },
      },
    },
  }
}
