import { ErrorResponse } from '@/responses/errors'
import { Session } from '@/models/user/session'
import { MainStore } from '@/store/MainStore'
import { ObjectParams } from '@/models/types'

const METHOD_GET = 'GET'
const METHOD_POST = 'POST'
const METHOD_DELETE = 'DELETE'
const METHOD_PUT = 'PUT'

const BASE_URL = process.env.VUE_APP_SERVER_URL

const baseRequest = (): RequestInit => {
  return {
    credentials: 'include',
    mode: 'cors',
  }
}

export const AUTH_HEADER = 'Auth-Token'
export const SESSION_HEADER = 'Session-Id'
export const MAX_LIMIT = 5000

export default class API {
  private static session: Session | null = null

  private static getSessionID(): string {
    return this.session ? this.session.sessID : ''
  }

  public static setSession(session: Session | null): void {
    this.session = session
  }

  static async get(url: string, headers: Map<string, string> | null = null): Promise<Response> {
    const init = {
      ...baseRequest(),
      ...{
        method: METHOD_GET,
        headers: this.headers(headers),
      },
    }

    return handleRequest(new Request(url, init))
  }

  static async post(
    url: string,
    body: unknown,
    headers: Map<string, string> | null = null
  ): Promise<Response> {
    const req = new Request(
      url,
      Object.assign(baseRequest, {
        method: METHOD_POST,
        headers: this.headers(headers),
        body: JSON.stringify(body),
      })
    )

    return handleRequest(req)
  }

  static async put(
    url: string,
    body: unknown,
    headers: Map<string, string> | null = null
  ): Promise<Response> {
    const req = new Request(
      url,
      Object.assign(baseRequest, {
        method: METHOD_PUT,
        headers: this.headers(headers),
        body: JSON.stringify(body),
      })
    )

    return handleRequest(req)
  }

  static async delete(url: string, headers: Map<string, string> | null = null): Promise<Response> {
    const req = new Request(
      url,
      Object.assign(baseRequest, {
        method: METHOD_DELETE,
        headers: this.headers(headers),
      })
    )

    return handleRequest(req)
  }

  private static headers(headers: Map<string, string> | null): Headers {
    const h = new Headers()
    h.append('Content-Type', 'application/json')
    h.append(SESSION_HEADER, this.getSessionID())
    if (headers) {
      headers.forEach((v, k) => h.append(k, v))
    }

    return h
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  static buildWsURL(resourceURL: string, params?: Record<string, any>): string {
    return API.buildURL(resourceURL, {
      ...(params ? params : {}),
      sessionToken: this.getSessionID(),
    }).replace(/^http/, 'ws')
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  static buildURL(resourceURL: string, params?: Record<string, any>): string {
    const url = (BASE_URL + resourceURL).replace(/[\\/\s]+$/g, '')
    return params
      ? url +
          '?' +
          Object.keys(params)
            .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
            .join('&')
      : url
  }
}

async function handleRequest(request: Request): Promise<Response> {
  if (!MainStore.isAuthorized() && !allowedRequestsWithoutAuth.isAllowed(request)) {
    const err = new Error('The server request was rejected due to an expired session')
    /* eslint-disable-next-line no-console */
    console.error(err.message)

    throw err
  }

  let response: Response
  try {
    response = await fetch(request)
  } catch (e) {
    throw new Error('Failed to get data. Server is not available')
  }

  if (response.status >= 400) {
    throw await ErrorByResponse(response)
  }

  return response
}

async function ErrorByResponse(r: Response): Promise<Error> {
  if (r.status == 401) {
    await MainStore.userLogout()
  }

  const err = new Error()

  try {
    const errResp: ErrorResponse = await r.json()
    err.name = r.status.toString()
    err.message = errResp.message
    if (errResp.details) {
      err.message += '\n' + JSON.stringify(errResp.details)
    }
  } catch (e) {
    err.message = r.status + ': ' + r.statusText
  }

  return err
}

export function paramsByIds(IDs: string[] | number[], name = 'ids'): ObjectParams {
  return IDs.length ? { [name]: IDs.join(',') } : {}
}

interface allowedItem {
  method: string
  pathRegExp: RegExp
}

class allowedRequests {
  items: allowedItem[] = []

  constructor(items: allowedItem[]) {
    this.add(items)
  }

  add = (items: allowedItem[]): allowedRequests => {
    this.items.push(...items)

    return this
  }

  isAllowed = (r: Request): boolean => {
    for (const item of this.items) {
      if (item.method.toLowerCase() == r.method.toLowerCase() && item.pathRegExp.test(r.url)) {
        return true
      }
    }

    return false
  }
}

const allowedRequestsWithoutAuth = new allowedRequests([
  {
    method: METHOD_POST,
    pathRegExp: new RegExp('^https?://[^/]+/users/auth$'),
  },
  {
    method: METHOD_POST,
    pathRegExp: new RegExp('^https?://[^/]+/users/auth/login$'),
  },
  {
    method: METHOD_POST,
    pathRegExp: new RegExp('^https?://[^/]+/users/auth/re-login$'),
  },
])
