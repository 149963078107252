import { ChartConfiguration } from 'chart.js'
import { CHART_COLORS, MONTHS } from '@/utils/chart'
import { PaymentMethodType } from '@/models/payments/constants'
import { paymentReport } from '@/components/dashboards/models/PaymentReport'
import { Money } from '@/models/Money'
import { tooltipCurrencyWithTotal } from '@/components/dashboards/models/common'

export function chartPayments(data: paymentReport): ChartConfiguration {
  const amountDataSEPA: number[] = []
  const amountDataCard: number[] = []
  const amountDataCash: number[] = []
  const amountDataBankTransfer: number[] = []
  const amountDataPartner: number[] = []
  const amountDataOnline: number[] = []

  Object.entries(data).forEach((mr) => {
    mr[1].paymentMethod[PaymentMethodType.SEPA]
      ? amountDataSEPA.push(new Money(mr[1].paymentMethod[PaymentMethodType.SEPA].amount).value)
      : amountDataSEPA.push(0)

    mr[1].paymentMethod[PaymentMethodType.Card]
      ? amountDataCard.push(new Money(mr[1].paymentMethod[PaymentMethodType.Card].amount).value)
      : amountDataCard.push(0)

    mr[1].paymentMethod[PaymentMethodType.Cash]
      ? amountDataCash.push(new Money(mr[1].paymentMethod[PaymentMethodType.Cash].amount).value)
      : amountDataCash.push(0)

    mr[1].paymentMethod[PaymentMethodType.BankTransfer]
      ? amountDataBankTransfer.push(
          new Money(mr[1].paymentMethod[PaymentMethodType.BankTransfer].amount).value
        )
      : amountDataBankTransfer.push(0)

    mr[1].paymentMethod[PaymentMethodType.Partner]
      ? amountDataPartner.push(
          new Money(mr[1].paymentMethod[PaymentMethodType.Partner].amount).value
        )
      : amountDataPartner.push(0)

    mr[1].paymentMethod[PaymentMethodType.Online]
      ? amountDataOnline.push(new Money(mr[1].paymentMethod[PaymentMethodType.Online].amount).value)
      : amountDataOnline.push(0)
  })

  return {
    type: 'bar',
    data: {
      labels: [...MONTHS],
      datasets: [
        {
          label: 'SEPA',
          data: [...amountDataSEPA],
          borderColor: CHART_COLORS.Red.main,
          backgroundColor: CHART_COLORS.Red.gradient,
          borderWidth: 1,
        },
        {
          label: 'Card',
          data: [...amountDataCard],
          borderColor: CHART_COLORS.Blue.main,
          backgroundColor: CHART_COLORS.Blue.gradient,
          borderWidth: 1,
        },
        {
          label: 'Online',
          data: [...amountDataOnline],
          borderColor: CHART_COLORS.Orange.main,
          backgroundColor: CHART_COLORS.Orange.gradient,
          borderWidth: 1,
        },
        {
          label: 'Cash',
          data: [...amountDataCash],
          borderColor: CHART_COLORS.Yellow.main,
          backgroundColor: CHART_COLORS.Yellow.gradient,
          borderWidth: 1,
        },
        {
          label: 'BankTransfer',
          data: [...amountDataBankTransfer],
          borderColor: CHART_COLORS.Green.main,
          backgroundColor: CHART_COLORS.Green.gradient,
          borderWidth: 1,
        },
        {
          label: 'Partner',
          data: [...amountDataPartner],
          borderColor: CHART_COLORS.Purple.main,
          backgroundColor: CHART_COLORS.Purple.gradient,
          borderWidth: 1,
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          align: 'start',
          text: 'Amounts of payments',
          font: {
            size: 14,
            family: 'tahoma',
            weight: 'bold',
            style: 'italic',
          },
        },
        tooltip: tooltipCurrencyWithTotal(),
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          beginAtZero: true,
          stacked: true,
          ticks: {
            callback: function (value) {
              return value.toLocaleString('es-ES', { style: 'currency', currency: 'EUR' })
            },
          },
        },
      },
    },
  }
}
