import { EnumNames } from '@/models/types'

export enum Status {
  Unknown = 0,
  Active = 1,
  Deprecated = 3,
}

export const statuses: EnumNames = {
  [Status.Active]: 'Active',
  [Status.Deprecated]: 'Deprecated',
}

export enum UnitType {
  Second = 1,
  Minute,
  Hour,
  Day,
  Week,
  Month,
  Year,
  Byte,
  Units,
}

export const unitTypes: EnumNames = {
  [UnitType.Second]: 'Second',
  [UnitType.Minute]: 'Minute',
  [UnitType.Hour]: 'Hour',
  [UnitType.Day]: 'Day',
  [UnitType.Week]: 'Week',
  [UnitType.Month]: 'Month',
  [UnitType.Year]: 'Year',
  [UnitType.Byte]: 'Byte',
  [UnitType.Units]: 'Units',
}
