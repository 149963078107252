import { ActionTree } from 'vuex'
import { MainStore } from '@/store/MainStore'
import { RootState } from '@/store'
import { CAN_VIEW_PAYMENT_INVOICES, CAN_VIEW_PAYMENTS } from '@/permissions/core_server'
import { PaymentsState } from '@/store/modules/payments/state'
import {
  M_INVOICES_OVERDUE_COUNT,
  M_PAYMENTS_OVERDUE_COUNT,
} from '@/store/modules/payments/mutations'
import { PaymentGet } from '@/models/payments/PaymentGet'
import { PaymentInvoiceGet } from '@/models/payments/PaymentInvoiceGet'

export const A_PAYMENTS_INIT = 'A_PAYMENTS_INIT'
export const A_PAYMENTS_INIT_ALL_UNPAID_OVERDUE = 'A_PAYMENTS_INIT_ALL_UNPAID_OVERDUE'
export const A_PAYMENTS_OVERDUE_COUNT = 'A_PAYMENTS_OVERDUE_COUNT'
export const A_INVOICES_OVERDUE_COUNT = 'A_INVOICES_OVERDUE_COUNT'

const countParams = { isOverdue30Day: true }

const actions: ActionTree<PaymentsState, RootState> = {
  [A_PAYMENTS_INIT]: async ({ dispatch }) => {
    if (!MainStore.isUserCanOne(CAN_VIEW_PAYMENTS, CAN_VIEW_PAYMENT_INVOICES)) {
      return
    }

    await dispatch(A_PAYMENTS_INIT_ALL_UNPAID_OVERDUE)
  },

  [A_PAYMENTS_INIT_ALL_UNPAID_OVERDUE]: async ({ dispatch }) => {
    await Promise.all([dispatch(A_PAYMENTS_OVERDUE_COUNT), dispatch(A_INVOICES_OVERDUE_COUNT)])
  },

  [A_PAYMENTS_OVERDUE_COUNT]: async ({ commit }) => {
    const count = await PaymentGet.count(countParams)
    commit(M_PAYMENTS_OVERDUE_COUNT, count)
  },

  [A_INVOICES_OVERDUE_COUNT]: async ({ commit }) => {
    const count = await PaymentInvoiceGet.count(countParams)
    commit(M_INVOICES_OVERDUE_COUNT, count)
  },
}

export default actions
