import { paymentsApi } from '@/api/payments-api'
import { MainStore } from '@/store/MainStore'
import { PaymentForRemesaContract, PaymentMakerRequest } from '@/requests/payment'
import { List } from '@/models/List'
import { ObjectParams } from '@/models/types'
import { Payment } from '@/models/payments/Payment'
import { MAX_LIMIT } from '@/api/api'

export class PaymentGet {
  static async list(params: ObjectParams): Promise<List<Payment>> {
    const list: List<Payment> = { count: 0, items: [] }
    try {
      const l = await paymentsApi.payment.list(params)
      list.count = l.count
      list.total = l.total
      list.items = l.items.map((v) => new Payment(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return list
  }

  static async count(params: ObjectParams): Promise<number> {
    let count = 0
    try {
      count = await paymentsApi.payment.count(params)
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return count
  }

  static async byID(id: number): Promise<Payment> {
    let item = new Payment()
    try {
      item = new Payment(await paymentsApi.payment.getByID(id))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return item
  }

  static async byIDs(ids: number[]): Promise<Payment[]> {
    let items: Payment[] = []
    try {
      items = (await paymentsApi.payment.getByIDs(ids)).map((v) => new Payment(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return items
  }

  static async mapByIDs(ids: number[]): Promise<Map<number, Payment>> {
    const res = await PaymentGet.byIDs(ids)

    const pMap = new Map<number, Payment>()
    res.forEach((v) => pMap.set(v.id, new Payment(v)))

    return pMap
  }

  static async total(req: PaymentMakerRequest): Promise<Payment> {
    let res = new Payment()
    try {
      res = new Payment(await paymentsApi.payment.total(req))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return res
  }

  static async remesaContract(req: PaymentForRemesaContract): Promise<Payment> {
    let item = new Payment()
    try {
      item = new Payment(await paymentsApi.payment.remesaContract(req))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return item
  }

  static async stories(id: number, params: ObjectParams = {}): Promise<List<Payment>> {
    const list: List<Payment> = { count: 0, items: [] }
    try {
      const l = await paymentsApi.payment.stories(id, { limit: MAX_LIMIT, ...params })
      list.count = l.count
      list.items = l.items.map((v) => new Payment(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return list
  }
}
