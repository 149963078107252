/* eslint-disable */
export function tooltipCurrency() {
  return {
    callbacks: {
      label: function (context: any) {
        let label = context.dataset.label || ''

        if (label) {
          label += ': '
        }
        if (context.parsed.y !== null) {
          label += new Intl.NumberFormat('es-ES', {
            style: 'currency',
            currency: 'EUR',
          }).format(context.parsed.y)
        }
        return label
      },
    },
  }
}

export function tooltipCurrencyWithTotal() {
  return {
    callbacks: {
      label: function (context: any) {
        const datasets = context.chart.data.datasets
        const dataIndex = context.dataIndex

        const total = datasets.reduce((sum: number, dataset: any) => {
          const value = dataset.data[dataIndex] as number
          return sum + (value || 0)
        }, 0)

        const totalLabel = `Total amount: ${new Intl.NumberFormat('es-ES', {
          style: 'currency',
          currency: 'EUR',
        }).format(total)}`

        const labels = datasets.map((dataset: any) => {
          let label = dataset.label || ''

          if (label) {
            label += ': '
          }
          const value = dataset.data[dataIndex]
          if (value !== null && value !== undefined) {
            label += new Intl.NumberFormat('es-ES', {
              style: 'currency',
              currency: 'EUR',
            }).format(value as number)
          }
          return label
        })

        return [totalLabel, ...labels]
      },
    },
  }
}

export function tooltipWithTotal() {
  return {
    callbacks: {
      label: function (context: any) {
        const datasets = context.chart.data.datasets
        const dataIndex = context.dataIndex

        const total = datasets.reduce((sum: number, dataset: any) => {
          const value = dataset.data[dataIndex] as number
          return sum + (value || 0)
        }, 0)

        const totalLabel = `Total: ${total}`

        const labels = datasets.map((dataset: any) => {
          let label = dataset.label || ''

          if (label) {
            label += ': '
          }
          const value = dataset.data[dataIndex]
          if (value !== null && value !== undefined) {
            label += value
          }
          return label
        })

        return [totalLabel, ...labels]
      },
    },
  }
}
