import { CommonModel, IModel } from '@/models/Model'
import { MainStore } from '@/store/MainStore'
import { paymentsApi } from '@/api/payments-api'

export interface IBankFail extends IModel {
  id: number
  code: string
  description: string
}

export class BankFail extends CommonModel implements IBankFail {
  id = 0
  code = ''
  description = ''

  constructor(src: IBankFail | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: IBankFail) {
    Object.assign(this, src)

    this.initModel(src)
  }

  async save(): Promise<boolean> {
    try {
      return this.id ? await this.update() : await this.add()
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async add(): Promise<boolean> {
    try {
      this.init(await paymentsApi.bankFail.add(this.toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async update(): Promise<boolean> {
    try {
      this.init(await paymentsApi.bankFail.update(this.toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async delete(): Promise<boolean> {
    if (!confirm(`Are you sure you want to delete?`)) {
      return false
    }

    try {
      await paymentsApi.bankFail.delete(this.id)
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  toInterface(): IBankFail {
    return {
      id: this.id,
      code: this.code,
      description: this.description,
    }
  }
}
