import { ChartConfiguration } from 'chart.js'
import { chartPayments } from '@/components/dashboards/models/chartPayments'
import { chartPaymentsCounts } from '@/components/dashboards/models/chartPaymentsCounts'
import { chartBankFails } from '@/components/dashboards/models/chartBankFails'
import { chartBankFailsCounts } from '@/components/dashboards/models/chartBankFailsCounts'
import { chartAverageBill } from '@/components/dashboards/models/chartAverageBill'

/*
type PaymentReport = struct {
	Month         MonthlyPaymentReport                          `json:"month"`
	PaymentMethod map[models.PaymentMethod]MonthlyPaymentReport `json:"paymentMethod"`
}
 */

export type paymentReport = {
  [key: number]: {
    month: monthlyReport
    paymentMethod: { [key in number]: monthlyReport }
  }
}
export type monthlyReports = {
  [key: number]: monthlyReport
}

interface monthlyReport {
  count: number
  amount: number
  average: number
}

export class PaymentReports {
  payments: paymentReport = {}
  bankFails: monthlyReports = {}
  averageBill: monthlyReports = {}

  paymentsChart: ChartConfiguration | null = null
  paymentsCountsChart: ChartConfiguration | null = null

  bankFailsChart: ChartConfiguration | null = null
  bankFailsCountsChart: ChartConfiguration | null = null

  averageBillChart: ChartConfiguration | null = null

  constructor(src: PaymentReports) {
    this.paymentsChart = chartPayments(src.payments)
    this.paymentsCountsChart = chartPaymentsCounts(src.payments)
    this.bankFailsChart = chartBankFails(src.bankFails)
    this.bankFailsCountsChart = chartBankFailsCounts(src.bankFails)
    this.averageBillChart = chartAverageBill(src.averageBill)
  }
}
