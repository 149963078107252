import { CommonModel, IModel } from '@/models/Model'
import { MainStore } from '@/store/MainStore'
import { paymentsApi } from '@/api/payments-api'
import { IPayment, Payment } from '@/models/payments/Payment'
import { dateString, downloadBlob, formatDate, formatDateTime, openNewTabBlob } from '@/utils'
import { IPaymentInvoiceLine, PaymentInvoiceLine } from '@/models/payments/PaymentInvoiceLine'
import {
  CurrencyType,
  currencyTypes,
  PaymentInvoiceStatus,
  paymentInvoiceStatuses,
} from '@/models/payments/constants'
import { Money } from '@/models/Money'
import { checkHtml } from '@/utils/html'
import { Receiver } from '@/models/payments/Receiver'
import { PersonSimple } from '@/models/persons/PersonSimple'
import { Company } from '@/models/payments/Company'
import { downloadApi } from '@/api/downloads-api'
import { Contract } from '@/models/persons/Contract'
import { contractToPersonRedirectHtml } from '@/models/persons/views'
import { EmailSendInvoice } from '@/requests/senders'
import { BankFail, IBankFail } from '@/models/payments/BankFail'
import { CAN_EDIT_PAYMENT_INVOICES } from '@/permissions/core_server'
import { Product } from '@/models/products/Product'
import router from '@/router'
import { ROUTER_PARAM_ID } from '@/utils/constants'
import { ROUTE_PAYMENTS_PAYMENT_INVOICES_VIEW } from '@/router/payments'
import { loaderWrapper } from '@/utils/loaderWrapper'
import { settings } from '@/models/Settings'

export interface IPaymentInvoice extends IModel {
  id: number
  personID: number
  companyID: number
  contractID: number
  receiverID: number | null
  returnedInvoiceID: number | null
  paymentID: number | null
  bankFailID: number | null
  number: number
  transactionNumber: string
  prefix: string
  narrative: string
  isReturn: boolean
  refunded: boolean
  subtotal: number
  vat: number
  status: PaymentInvoiceStatus
  vatPercentage: number
  currency: CurrencyType
  dueDate: Date | null
  paidAt: Date | null
  createdAt: Date | null
  createdBy: number
  payment: IPayment | null
  bankFail: IBankFail | null
  lines: IPaymentInvoiceLine[]
}

export class PaymentInvoice extends CommonModel implements IPaymentInvoice {
  id = 0
  personID = 0
  companyID = 0
  contractID = 0
  receiverID: number | null = null
  returnedInvoiceID: number | null = null
  paymentID: number | null = null
  bankFailID: number | null = null
  number = 0
  transactionNumber = ''
  prefix = ''
  narrative = ''
  isReturn = false
  refunded = false
  subtotal = 0
  vat = 0
  status = PaymentInvoiceStatus.Paid
  vatPercentage = settings().vat
  currency = 0
  dueDate: Date | null = null
  paidAt: Date | null = null
  createdAt: Date | null = null
  createdBy = 0
  payment: Payment | null = null
  bankFail: BankFail | null = null
  lines: PaymentInvoiceLine[] = []

  // for Form
  createdAtForm = ''
  paidAtForm = ''
  dueDateForm = ''
  moneySubtotal: Money = new Money()
  moneyVAT: Money = new Money()
  whoCreated: PersonSimple | null = null

  // after load
  person: PersonSimple | null = null
  receiver: Receiver | null = null
  company: Company | null = null
  contract: Contract | null = null
  returnedInvoice: PaymentInvoice | null = null

  get canBeRefund(): boolean {
    return (
      this.status == PaymentInvoiceStatus.Paid &&
      !this.paymentID &&
      !this.isReturn &&
      !this.refunded
    )
  }

  get facturaNumber(): string {
    const res = this.isReturn ? `${this.prefix}/${this.number}R` : `${this.prefix}/${this.number}`
    return res.replace(/\/+/, '/')
  }

  get returnedInvoiceFacturaNumber(): string {
    return this.returnedInvoice ? this.returnedInvoice.facturaNumber : ''
  }

  get createdAtStr(): string {
    return formatDateTime(this.createdAt)
  }

  get paidAtStr(): string {
    return formatDateTime(this.paidAt)
  }

  get dueDateStr(): string {
    return formatDate(this.dueDate)
  }

  get statusStr(): string {
    return paymentInvoiceStatuses[this.status]
  }

  get statusHtml(): string {
    let textColorClass = 'font-weight-bold '
    switch (this.status) {
      case PaymentInvoiceStatus.Paid:
        textColorClass += 'text-success'
        break
      case PaymentInvoiceStatus.Unpaid:
        textColorClass += 'text-danger'
        break
      case PaymentInvoiceStatus.Refund:
        textColorClass += 'text-info'
        break
    }

    return `<span class="${textColorClass}">${this.statusStr}</span>`
  }

  get currencyName(): string {
    return currencyTypes[this.currency]
  }

  get isReturnHtml(): string {
    return checkHtml(this.isReturn)
  }

  get totalDiscount(): Money {
    return new Money(this.lines.reduce((acc, line) => acc + line.discountValue, 0))
  }

  get personFullName(): string {
    return this.person ? this.person.fullName : ''
  }

  get personLinkHtml(): string {
    return this.person ? this.person.viewLinkHtml('_self') : ''
  }

  get companyName(): string {
    return this.company ? this.company.name : ''
  }

  get paymentMethodHtml(): string {
    return (this.receiver ? this.receiver.shortName : '').replace(/[ ,]+$/, '')
  }

  get whoCreatedName(): string {
    return this.whoCreated ? this.whoCreated.fullName : ''
  }

  get contractToPersonRedirectHtml(): string {
    return contractToPersonRedirectHtml(this.contractID)
  }

  get canBeEdited(): boolean {
    return !this.isReturn && !this.paymentID && MainStore.isUserCan(CAN_EDIT_PAYMENT_INVOICES)
  }

  constructor(src: IPaymentInvoice | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: IPaymentInvoice) {
    Object.assign(this, src)

    this.createdAt = this.dateOrNull(src.createdAt)
    this.paidAt = this.dateOrNull(src.paidAt)
    this.payment = src.payment ? new Payment(src.payment) : null
    this.bankFail = src.bankFail ? new BankFail(src.bankFail) : null
    this.moneySubtotal = new Money(src.subtotal)
    this.moneyVAT = new Money(src.vat)

    if (src instanceof PaymentInvoice) {
      this.lines = src.lines ? [...src.lines] : []
      this.createdAtForm = src.createdAtForm
      this.paidAtForm = src.paidAtForm
      this.dueDateForm = src.dueDateForm
    } else {
      this.lines = src.lines ? src.lines.map((v) => new PaymentInvoiceLine(v)) : []
      this.createdAtForm = dateString(this.createdAt)
      this.paidAtForm = dateString(this.paidAt)
      this.dueDateForm = dateString(this.dueDate)
    }

    this.moneySubtotal.withVatPercentage(this.vatPercentage)
    this.moneyVAT.withVatPercentage(this.vatPercentage)
    this.lines.forEach((v) => v.setVatPercentage(this.vatPercentage))

    this.initModel(src)
  }

  totalByProducts(ids: number[]): Money {
    let res = 0

    this.lines.forEach((v) => {
      if (!ids.length || (v.productID && ids.includes(v.productID))) {
        res += v.total
      }
    })

    return new Money(res)
  }

  products(ids: number[] = []): Product[] {
    const res: Product[] = []

    this.lines.forEach((v) => {
      if (v.product) {
        if (ids.length) {
          ids.includes(v.product.id) && res.push(v.product)
        } else {
          res.push(v.product)
        }
      }
    })

    return res
  }

  toViewInvoiceHtml(target = '_self'): string {
    const res = router.resolve({
      name: ROUTE_PAYMENTS_PAYMENT_INVOICES_VIEW,
      params: {
        [ROUTER_PARAM_ID]: this.id.toString(),
      },
    })

    return `<a href="${res.href}" target="${target}">${this.facturaNumber}</a>`
  }

  async save(): Promise<boolean> {
    try {
      return this.id ? await this.update() : await this.add()
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async add(): Promise<boolean> {
    try {
      this.init(await paymentsApi.paymentInvoice.add(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async update(): Promise<boolean> {
    try {
      this.init(await paymentsApi.paymentInvoice.update(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async send(req: EmailSendInvoice): Promise<boolean> {
    try {
      await MainStore.setLoading()
      await paymentsApi.paymentInvoice.send(req)
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    } finally {
      await MainStore.releaseLoading()
    }
  }

  async refund(): Promise<boolean> {
    if (!confirm(`Do you want to refund this invoice?`)) {
      return false
    }

    try {
      await paymentsApi.paymentInvoice.refund({ id: this.id })
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  calculate(): void {
    let subtotal = 0
    this.lines.forEach((v) => {
      v.calculate(this.vatPercentage)
      subtotal += v.total
    })

    this.subtotal = subtotal
    this.moneySubtotal = new Money(subtotal).withVatPercentage(this.vatPercentage)
    this.moneyVAT = Money.withValue(this.moneySubtotal.vat).withVatPercentage(this.vatPercentage)
    this.vat = this.moneyVAT.forStorage
  }

  async print(inNewTab = true): Promise<void> {
    await loaderWrapper(async () => {
      const res = await downloadApi.payment.invoice({ id: this.id })
      inNewTab
        ? openNewTabBlob(res)
        : downloadBlob(res, 'invoice' + '_' + this.facturaNumber.replace(/\/+/, '_'))
    })
  }

  prepare(): PaymentInvoice {
    if (this.receiver) {
      this.receiverID = this.receiver.id
      this.companyID = this.receiver.companyID
    } else if (this.company) {
      this.companyID = this.company.id
    }

    this.contractID = this.contract ? this.contract.id : this.contractID
    this.dueDate = this.dueDateForm ? new Date(this.dueDateForm) : this.dueDate

    return this
  }

  toInterface(): IPaymentInvoice {
    return {
      id: this.id,
      personID: this.personID,
      companyID: this.companyID,
      contractID: this.contractID,
      receiverID: this.receiverID,
      returnedInvoiceID: this.returnedInvoiceID,
      paymentID: this.paymentID,
      bankFailID: this.bankFailID,
      number: this.number,
      transactionNumber: this.transactionNumber,
      prefix: this.prefix,
      narrative: this.narrative,
      isReturn: this.isReturn,
      refunded: this.refunded,
      subtotal: this.subtotal,
      vat: this.vat,
      status: this.status,
      vatPercentage: this.vatPercentage,
      currency: this.currency,
      dueDate: this.dueDate,
      paidAt: this.paidAt,
      createdAt: this.createdAt,
      createdBy: this.createdBy,
      lines: this.lines.map((v) => v.toInterface()),
      payment: null,
      bankFail: null,
    }
  }
}
